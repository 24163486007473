import { APP_BASE_HREF, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { TranslatedSlug } from '@valk-nx/compositions/ui-header/src/lib/header.interface';
import { removeTrailingSlash } from '@valk-nx/core/lib/ssr/ssr.helper';
import {
	Link,
	PageMetaData,
} from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Injectable({ providedIn: 'root' })
export class MetadataService {
	constructor(
		private metaTagService: Meta,
		private titleService: Title,
		@Inject(PLATFORM_ID) private platformId: string,
		@Optional() @Inject(APP_BASE_HREF) protected hostUrl: string,
		private router: Router,
		@Inject(DOCUMENT) private readonly document: Document,
	) {
		if (isPlatformBrowser(this.platformId) && !this.hostUrl) {
			this.hostUrl = `${window.location.protocol}//${window.location.host}`;
		}
	}

	updateMetadata(metadata: PageMetaData): void {
		const metatags: MetaDefinition[] =
			this.generateMetaDefinitions(metadata);

		this.removeMetaDefinitions();
		this.metaTagService.addTags([
			...metatags,
			{
				property: 'og:url',
				content: `${this.hostUrl}${this.router.url}`,
			},
			{
				name: 'robots',
				content: this.generateRobots(
					metadata.robotsNoIndex,
					metadata.robotsNoFollow,
				),
			},
		]);

		this.titleService.setTitle(metadata.metaTitle);
	}

	removeMetaDefinitions(): void {
		this.metaTagService.removeTag("name='title'");
		this.metaTagService.removeTag("property='og:title'");
		this.metaTagService.removeTag("name='description'");
		this.metaTagService.removeTag("property='og:description'");
		this.metaTagService.removeTag("property='og:image'");
		this.metaTagService.removeTag("property='og:type'");
		this.metaTagService.removeTag("property='og:url'");
		this.metaTagService.removeTag("name='robots'");
	}

	generateMetaDefinitions(metadata: PageMetaData): MetaDefinition[] {
		return [
			{ name: 'title', content: metadata.metaTitle },
			{ property: 'og:title', content: metadata.openGraphTitle },
			{ name: 'description', content: metadata.metaDescription },
			{
				property: 'og:description',
				content: metadata.openGraphDescription,
			},
			{ name: 'og:image', content: metadata.openGraphImage?.file },
			{ property: 'og:type', content: metadata.openGraphType },
		];
	}

	generateRobots(noIndex: boolean, noFollow: boolean): string {
		let robots = noIndex ? 'noindex' : 'index';
		robots = noFollow ? `${robots}, nofollow` : `${robots}, follow`;
		return robots;
	}

	createLinkForCanonicalURL(canonical: Link | null) {
		let href = `${this.hostUrl}${this.router.url}`;

		if (canonical?.cached_url?.startsWith('http')) {
			href = canonical.cached_url;
		} else if (canonical?.cached_url) {
			const route = canonical.cached_url.startsWith('/')
				? canonical.cached_url
				: `/${canonical.cached_url}`;
			href = `${this.hostUrl}${route}`;
		}

		const existingLink: HTMLLinkElement | null =
			this.document.querySelector('link[rel="canonical"]');

		const link: HTMLLinkElement =
			existingLink || this.document.createElement('link');

		link.setAttribute('rel', 'canonical');

		this.document.head.appendChild(link);
		link.setAttribute('href', removeTrailingSlash(href));
	}

	setHrefLang(translatedSlugs: TranslatedSlug[], defaultLanguage: string) {
		const defaultSlug = {
			path: '',
			...translatedSlugs.find(
				(slug) =>
					slug.lang.toLowerCase() === defaultLanguage.toLowerCase(),
			),
			lang: 'x-default',
		};

		[...translatedSlugs, defaultSlug].map((translatedSlug) => {
			const path = translatedSlug.path.endsWith('home')
				? translatedSlug.path.replace(/\/?home$/, '')
				: translatedSlug.path;

			const headDomElements = Array.from(
				this.document.head.children,
			) as unknown as HTMLLinkElement[];
			const alternateLink = headDomElements.find(
				(child: HTMLLinkElement) =>
					child.rel == 'alternate' &&
					child.hreflang.toLowerCase() ==
						translatedSlug.lang.toLowerCase(),
			);

			const href = removeTrailingSlash(`${this.hostUrl}/${path}`);

			if (alternateLink) {
				alternateLink.setAttribute('href', href);
			} else {
				const link = this.document.createElement('link');
				link.setAttribute('rel', 'alternate');
				this.document.head.appendChild(link);
				link.setAttribute(
					'hreflang',
					translatedSlug.lang.toLowerCase(),
				);
				link.setAttribute('href', href);
			}
		});
	}
}
