import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { DynamicModule } from 'ng-dynamic-component';
import {
	combineLatest,
	from,
	map,
	Observable,
	switchMap,
} from 'rxjs';

import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { Language } from '@valk-nx/core/lib/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Components } from '@valk-nx/storyblok-core/src/lib/components';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { PackageData } from '@valk-nx/storyblok-services/src/lib/services/content.interface';
import { ContentService } from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

@Component({
	selector: 'sb-package-overview',
	templateUrl: './package-overview.component.html',
	standalone: true,
	imports: [
		CommonModule,
		DynamicModule,
		PersuadeCardComponent,
		StoryblokDirective,
		TranslateModule,
	],
})
export class PackageOverviewComponent
	extends StoryblokRootDirective
{
	@Input({ required: true }) content!: unknown[];

	// Needed to "mute" errors in console
	@Input({ required: true }) canonical!: string;
	@Input({ required: true }) hideFromSitemap!: boolean;
	@Input({ required: true }) robotsNoIndex!: boolean;
	@Input({ required: true }) robotsNoFollow!: boolean;
	@Input({ required: true }) openGraphTitle!: string;
	@Input({ required: true }) openGraphDescription!: string;
	@Input({ required: true }) openGraphType!: string;
	@Input({ required: true }) openGraphImage!: { alt: string; file: string };
	@Input({ required: true }) metaDescription!: string;
	@Input({ required: true }) metaTitle!: string;

	components: typeof Components;

	currentUrl = '';

	packages$: Observable<PackageData[]>;

	constructor(
		private readonly sbService: StoryblokService,
		private readonly contentService: ContentService,
		private router: Router,
	) {
		super();

		// eslint-disable-next-line @nx/enforce-module-boundaries
		import('@valk-nx/storyblok-core/src/lib/components')
			.then((cp) => {
				this.components = cp.Components;
			})
			.catch(
				/* istanbul ignore next */ (error) => {
					console.error(error);
				},
			);

		this.packages$ =
			this.sbService.translatedSlugs$.pipe(
			takeUntilDestroyed(),
			map((translatedSlugs) => {
				const selectedSlug =
					translatedSlugs.find((x) => x.selected) ||
					translatedSlugs[0];
				const path =
					selectedSlug.lang.toLowerCase() !== 'nl'
						? selectedSlug.path.substring(3)
						: selectedSlug.path;
				return {
					path,
					language: selectedSlug.lang.toLowerCase() as Language,
				};
			}),
			switchMap(({ path, language }) =>
				combineLatest([
					from(
						this.sbService.getStoriesByQuery(
							{
								starts_with: path,
								excluding_slugs: path,
							},
							language,
						),
					),
					this.contentService.getPackages(language),
				]),
			),
			map(([storiesResponse, packagesResponse]) => {
				const storyblokPackages = storiesResponse.data.stories;
				const availablePackages = packagesResponse.body.data;

				return availablePackages.filter((availablePackage) =>
					storyblokPackages.some(
						(storyblokPackage) =>
							storyblokPackage.full_slug.split('/').pop() ===
							availablePackage.slug,
					),
				);
			}),
		);

		this.currentUrl = this.router.url;
	}
}
