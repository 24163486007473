var w = Object.defineProperty;
var S = (o, e, t) => e in o ? w(o, e, {
  enumerable: !0,
  configurable: !0,
  writable: !0,
  value: t
}) : o[e] = t;
var c = (o, e, t) => S(o, typeof e != "symbol" ? e + "" : e, t);
function v(o) {
  return !(o !== o || o === 1 / 0 || o === -1 / 0);
}
function x(o, e, t) {
  if (!v(e)) throw new TypeError("Expected `limit` to be a finite number");
  if (!v(t)) throw new TypeError("Expected `interval` to be a finite number");
  const r = [];
  let s = [],
    i = 0;
  const n = function () {
      i++;
      const a = setTimeout(function () {
        i--, r.length > 0 && n(), s = s.filter(function (h) {
          return h !== a;
        });
      }, t);
      s.indexOf(a) < 0 && s.push(a);
      const u = r.shift();
      u.resolve(o.apply(u.self, u.args));
    },
    l = function (...a) {
      const u = this;
      return new Promise(function (h, f) {
        r.push({
          resolve: h,
          reject: f,
          args: a,
          self: u
        }), i < e && n();
      });
    };
  return l.abort = function () {
    s.forEach(clearTimeout), s = [], r.forEach(function (a) {
      a.reject(function () {
        Error.call(this, "Throttled function aborted"), this.name = "AbortError";
      });
    }), r.length = 0;
  }, l;
}
class k {
  constructor() {
    c(this, "isCDNUrl", (e = "") => e.indexOf("/cdn/") > -1);
    c(this, "getOptionsPage", (e, t = 25, r = 1) => ({
      ...e,
      per_page: t,
      page: r
    }));
    c(this, "delay", e => new Promise(t => setTimeout(t, e)));
    c(this, "arrayFrom", (e = 0, t) => [...Array(e)].map(t));
    c(this, "range", (e = 0, t = e) => {
      const r = Math.abs(t - e) || 0,
        s = e < t ? 1 : -1;
      return this.arrayFrom(r, (i, n) => n * s + e);
    });
    c(this, "asyncMap", async (e, t) => Promise.all(e.map(t)));
    c(this, "flatMap", (e = [], t) => e.map(t).reduce((r, s) => [...r, ...s], []));
    /**
     * @method escapeHTML
     * @param  {String} string text to be parsed
     * @return {String} Text parsed
     */
    c(this, "escapeHTML", function (e) {
      const t = {
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&#39;"
        },
        r = /[&<>"']/g,
        s = RegExp(r.source);
      return e && s.test(e) ? e.replace(r, i => t[i]) : e;
    });
  }
  /**
   * @method stringify
   * @param  {Object} params
   * @param  {String} prefix
   * @param  {Boolean} isArray
   * @return {String} Stringified object
   */
  stringify(e, t, r) {
    const s = [];
    for (const i in e) {
      if (!Object.prototype.hasOwnProperty.call(e, i)) continue;
      const n = e[i],
        l = r ? "" : encodeURIComponent(i);
      let a;
      typeof n == "object" ? a = this.stringify(n, t ? t + encodeURIComponent("[" + l + "]") : l, Array.isArray(n)) : a = (t ? t + encodeURIComponent("[" + l + "]") : l) + "=" + encodeURIComponent(n), s.push(a);
    }
    return s.join("&");
  }
  /**
   * @method getRegionURL
   * @param  {String} regionCode region code, could be eu, us, cn, ap or ca
   * @return {String} The base URL of the region
   */
  getRegionURL(e) {
    const t = "api.storyblok.com",
      r = "api-us.storyblok.com",
      s = "app.storyblokchina.cn",
      i = "api-ap.storyblok.com",
      n = "api-ca.storyblok.com";
    switch (e) {
      case "us":
        return r;
      case "cn":
        return s;
      case "ap":
        return i;
      case "ca":
        return n;
      default:
        return t;
    }
  }
}
const P = function (o, e) {
    const t = {};
    for (const r in o) {
      const s = o[r];
      e.indexOf(r) > -1 && s !== null && (t[r] = s);
    }
    return t;
  },
  A = o => o === "email",
  C = () => ({
    singleTag: "hr"
  }),
  O = () => ({
    tag: "blockquote"
  }),
  L = () => ({
    tag: "ul"
  }),
  E = o => ({
    tag: ["pre", {
      tag: "code",
      attrs: o.attrs
    }]
  }),
  U = () => ({
    singleTag: "br"
  }),
  H = o => ({
    tag: `h${o.attrs.level}`
  }),
  j = o => ({
    singleTag: [{
      tag: "img",
      attrs: P(o.attrs, ["src", "alt", "title"])
    }]
  }),
  N = () => ({
    tag: "li"
  }),
  I = () => ({
    tag: "ol"
  }),
  M = () => ({
    tag: "p"
  }),
  z = o => ({
    tag: [{
      tag: "span",
      attrs: {
        "data-type": "emoji",
        "data-name": o.attrs.name,
        emoji: o.attrs.emoji
      }
    }]
  }),
  q = () => ({
    tag: "b"
  }),
  V = () => ({
    tag: "s"
  }),
  F = () => ({
    tag: "u"
  }),
  J = () => ({
    tag: "strong"
  }),
  B = () => ({
    tag: "code"
  }),
  K = () => ({
    tag: "i"
  }),
  G = o => {
    if (!o.attrs) return {
      tag: ""
    };
    const e = new k().escapeHTML,
      t = {
        ...o.attrs
      },
      {
        linktype: r = "url"
      } = o.attrs;
    if (delete t.linktype, t.href && (t.href = e(o.attrs.href || "")), A(r) && (t.href = `mailto:${t.href}`), t.anchor && (t.href = `${t.href}#${t.anchor}`, delete t.anchor), t.custom) {
      for (const s in t.custom) t[s] = t.custom[s];
      delete t.custom;
    }
    return {
      tag: [{
        tag: "a",
        attrs: t
      }]
    };
  },
  W = o => ({
    tag: [{
      tag: "span",
      attrs: o.attrs
    }]
  }),
  Y = () => ({
    tag: "sub"
  }),
  Q = () => ({
    tag: "sup"
  }),
  X = o => ({
    tag: [{
      tag: "span",
      attrs: o.attrs
    }]
  }),
  Z = o => {
    var t;
    return (t = o.attrs) != null && t.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `background-color:${o.attrs.color};`
        }
      }]
    } : {
      tag: ""
    };
  },
  D = o => {
    var t;
    return (t = o.attrs) != null && t.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `color:${o.attrs.color}`
        }
      }]
    } : {
      tag: ""
    };
  },
  ee = {
    nodes: {
      horizontal_rule: C,
      blockquote: O,
      bullet_list: L,
      code_block: E,
      hard_break: U,
      heading: H,
      image: j,
      list_item: N,
      ordered_list: I,
      paragraph: M,
      emoji: z
    },
    marks: {
      bold: q,
      strike: V,
      underline: F,
      strong: J,
      code: B,
      italic: K,
      link: G,
      styled: W,
      subscript: Y,
      superscript: Q,
      anchor: X,
      highlight: Z,
      textStyle: D
    }
  },
  te = function (o) {
    const e = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;"
      },
      t = /[&<>"']/g,
      r = RegExp(t.source);
    return o && r.test(o) ? o.replace(t, s => e[s]) : o;
  };
class se {
  constructor(e) {
    c(this, "marks");
    c(this, "nodes");
    e || (e = ee), this.marks = e.marks || [], this.nodes = e.nodes || [];
  }
  addNode(e, t) {
    this.nodes[e] = t;
  }
  addMark(e, t) {
    this.marks[e] = t;
  }
  render(e, t = {
    optimizeImages: !1
  }) {
    if (console.warn("Warning ⚠️: The RichTextResolver class is deprecated and will be removed in the next major release. Please use the `@storyblok/richtext` package instead. https://github.com/storyblok/richtext/"), e && e.content && Array.isArray(e.content)) {
      let r = "";
      return e.content.forEach(s => {
        r += this.renderNode(s);
      }), t.optimizeImages ? this.optimizeImages(r, t.optimizeImages) : r;
    }
    return console.warn(`The render method must receive an Object with a "content" field.
			The "content" field must be an array of nodes as the type ISbRichtext.
			ISbRichtext:
				content?: ISbRichtext[]
				marks?: ISbRichtext[]
				attrs?: any
				text?: string
				type: string
				
				Example:
				{
					content: [
						{
							content: [
								{
									text: 'Hello World',
									type: 'text'
								}
							],
							type: 'paragraph'
						}
					],
					type: 'doc'
				}`), "";
  }
  optimizeImages(e, t) {
    let r = 0,
      s = 0,
      i = "",
      n = "";
    typeof t != "boolean" && (typeof t.width == "number" && t.width > 0 && (i += `width="${t.width}" `, r = t.width), typeof t.height == "number" && t.height > 0 && (i += `height="${t.height}" `, s = t.height), (t.loading === "lazy" || t.loading === "eager") && (i += `loading="${t.loading}" `), typeof t.class == "string" && t.class.length > 0 && (i += `class="${t.class}" `), t.filters && (typeof t.filters.blur == "number" && t.filters.blur >= 0 && t.filters.blur <= 100 && (n += `:blur(${t.filters.blur})`), typeof t.filters.brightness == "number" && t.filters.brightness >= -100 && t.filters.brightness <= 100 && (n += `:brightness(${t.filters.brightness})`), t.filters.fill && (t.filters.fill.match(/[0-9A-Fa-f]{6}/g) || t.filters.fill === "transparent") && (n += `:fill(${t.filters.fill})`), t.filters.format && ["webp", "png", "jpeg"].includes(t.filters.format) && (n += `:format(${t.filters.format})`), typeof t.filters.grayscale == "boolean" && t.filters.grayscale && (n += ":grayscale()"), typeof t.filters.quality == "number" && t.filters.quality >= 0 && t.filters.quality <= 100 && (n += `:quality(${t.filters.quality})`), t.filters.rotate && [90, 180, 270].includes(t.filters.rotate) && (n += `:rotate(${t.filters.rotate})`), n.length > 0 && (n = "/filters" + n))), i.length > 0 && (e = e.replace(/<img/g, `<img ${i.trim()}`));
    const l = r > 0 || s > 0 || n.length > 0 ? `${r}x${s}${n}` : "";
    return e = e.replace(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g, `a.storyblok.com/f/$1/$2.$3/m/${l}`), typeof t != "boolean" && (t.sizes || t.srcset) && (e = e.replace(/<img.*?src=["|'](.*?)["|']/g, a => {
      var h, f;
      const u = a.match(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g);
      if (u && u.length > 0) {
        const d = {
          srcset: (h = t.srcset) == null ? void 0 : h.map(g => {
            if (typeof g == "number") return `//${u}/m/${g}x0${n} ${g}w`;
            if (typeof g == "object" && g.length === 2) {
              let b = 0,
                _ = 0;
              return typeof g[0] == "number" && (b = g[0]), typeof g[1] == "number" && (_ = g[1]), `//${u}/m/${b}x${_}${n} ${b}w`;
            }
          }).join(", "),
          sizes: (f = t.sizes) == null ? void 0 : f.map(g => g).join(", ")
        };
        let p = "";
        return d.srcset && (p += `srcset="${d.srcset}" `), d.sizes && (p += `sizes="${d.sizes}" `), a.replace(/<img/g, `<img ${p.trim()}`);
      }
      return a;
    })), e;
  }
  renderNode(e) {
    const t = [];
    e.marks && e.marks.forEach(s => {
      const i = this.getMatchingMark(s);
      i && i.tag !== "" && t.push(this.renderOpeningTag(i.tag));
    });
    const r = this.getMatchingNode(e);
    return r && r.tag && t.push(this.renderOpeningTag(r.tag)), e.content ? e.content.forEach(s => {
      t.push(this.renderNode(s));
    }) : e.text ? t.push(te(e.text)) : r && r.singleTag ? t.push(this.renderTag(r.singleTag, " /")) : r && r.html ? t.push(r.html) : e.type === "emoji" && t.push(this.renderEmoji(e)), r && r.tag && t.push(this.renderClosingTag(r.tag)), e.marks && e.marks.slice(0).reverse().forEach(s => {
      const i = this.getMatchingMark(s);
      i && i.tag !== "" && t.push(this.renderClosingTag(i.tag));
    }), t.join("");
  }
  renderTag(e, t) {
    return e.constructor === String ? `<${e}${t}>` : e.map(s => {
      if (s.constructor === String) return `<${s}${t}>`;
      {
        let i = `<${s.tag}`;
        if (s.attrs) {
          for (const n in s.attrs) if (Object.prototype.hasOwnProperty.call(s.attrs, n)) {
            const l = s.attrs[n];
            l !== null && (i += ` ${n}="${l}"`);
          }
        }
        return `${i}${t}>`;
      }
    }).join("");
  }
  renderOpeningTag(e) {
    return this.renderTag(e, "");
  }
  renderClosingTag(e) {
    return e.constructor === String ? `</${e}>` : e.slice(0).reverse().map(r => r.constructor === String ? `</${r}>` : `</${r.tag}>`).join("");
  }
  getMatchingNode(e) {
    const t = this.nodes[e.type];
    if (typeof t == "function") return t(e);
  }
  getMatchingMark(e) {
    const t = this.marks[e.type];
    if (typeof t == "function") return t(e);
  }
  renderEmoji(e) {
    if (e.attrs.emoji) return e.attrs.emoji;
    const t = [{
      tag: "img",
      attrs: {
        src: e.attrs.fallbackImage,
        draggable: "false",
        loading: "lazy",
        align: "absmiddle"
      }
    }];
    return this.renderTag(t, " /");
  }
}
const T = se;
class re {
  constructor(e) {
    c(this, "baseURL");
    c(this, "timeout");
    c(this, "headers");
    c(this, "responseInterceptor");
    c(this, "fetch");
    c(this, "ejectInterceptor");
    c(this, "url");
    c(this, "parameters");
    c(this, "fetchOptions");
    this.baseURL = e.baseURL, this.headers = e.headers || new Headers(), this.timeout = e != null && e.timeout ? e.timeout * 1e3 : 0, this.responseInterceptor = e.responseInterceptor, this.fetch = (...t) => e.fetch ? e.fetch(...t) : fetch(...t), this.ejectInterceptor = !1, this.url = "", this.parameters = {}, this.fetchOptions = {};
  }
  /**
   *
   * @param url string
   * @param params ISbStoriesParams
   * @returns Promise<ISbResponse | Error>
   */
  get(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("get");
  }
  post(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("post");
  }
  put(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("put");
  }
  delete(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("delete");
  }
  async _responseHandler(e) {
    const t = [],
      r = {
        data: {},
        headers: {},
        status: 0,
        statusText: ""
      };
    e.status !== 204 && (await e.json().then(s => {
      r.data = s;
    }));
    for (const s of e.headers.entries()) t[s[0]] = s[1];
    return r.headers = {
      ...t
    }, r.status = e.status, r.statusText = e.statusText, r;
  }
  async _methodHandler(e) {
    let t = `${this.baseURL}${this.url}`,
      r = null;
    if (e === "get") {
      const a = new k();
      t = `${this.baseURL}${this.url}?${a.stringify(this.parameters)}`;
    } else r = JSON.stringify(this.parameters);
    const s = new URL(t),
      i = new AbortController(),
      {
        signal: n
      } = i;
    let l;
    this.timeout && (l = setTimeout(() => i.abort(), this.timeout));
    try {
      const a = await this.fetch(`${s}`, {
        method: e,
        headers: this.headers,
        body: r,
        signal: n,
        ...this.fetchOptions
      });
      this.timeout && clearTimeout(l);
      const u = await this._responseHandler(a);
      return this.responseInterceptor && !this.ejectInterceptor ? this._statusHandler(this.responseInterceptor(u)) : this._statusHandler(u);
    } catch (a) {
      return {
        message: a
      };
    }
  }
  setFetchOptions(e = {}) {
    Object.keys(e).length > 0 && "method" in e && delete e.method, this.fetchOptions = {
      ...e
    };
  }
  eject() {
    this.ejectInterceptor = !0;
  }
  _statusHandler(e) {
    const t = /20[0-6]/g;
    return new Promise((r, s) => {
      if (t.test(`${e.status}`)) return r(e);
      const i = {
        message: e.statusText,
        status: e.status,
        response: Array.isArray(e.data) ? e.data[0] : e.data.error || e.data.slug
      };
      s(i);
    });
  }
}
const ie = re,
  $ = "SB-Agent",
  R = {
    defaultAgentName: "SB-JS-CLIENT",
    defaultAgentVersion: "SB-Agent-Version",
    packageVersion: "6.0.0"
  };
let m = {};
const y = {};
class oe {
  /**
   *
   * @param config ISbConfig interface
   * @param endpoint string, optional
   */
  constructor(e, t) {
    c(this, "client");
    c(this, "maxRetries");
    c(this, "retriesDelay");
    c(this, "throttle");
    c(this, "accessToken");
    c(this, "cache");
    c(this, "helpers");
    c(this, "resolveCounter");
    c(this, "relations");
    c(this, "links");
    // TODO: Remove on v7.x.x
    c(this, "richTextResolver");
    c(this, "resolveNestedRelations");
    c(this, "stringifiedStoriesCache");
    let r = e.endpoint || t;
    if (!r) {
      const n = new k().getRegionURL,
        l = e.https === !1 ? "http" : "https";
      e.oauthToken ? r = `${l}://${n(e.region)}/v1` : r = `${l}://${n(e.region)}/v2`;
    }
    const s = new Headers();
    s.set("Content-Type", "application/json"), s.set("Accept", "application/json"), e.headers && e.headers.entries().toArray().forEach(([l, a]) => {
      s.set(l, a);
    }), s.has($) || (s.set($, R.defaultAgentName), s.set(R.defaultAgentVersion, R.packageVersion));
    let i = 5;
    e.oauthToken && (s.set("Authorization", e.oauthToken), i = 3), e.rateLimit && (i = e.rateLimit), e.richTextSchema ? this.richTextResolver = new T(e.richTextSchema) : this.richTextResolver = new T(), e.componentResolver && this.setComponentResolver(e.componentResolver), this.maxRetries = e.maxRetries || 10, this.retriesDelay = 300, this.throttle = x(this.throttledRequest, i, 1e3), this.accessToken = e.accessToken || "", this.relations = {}, this.links = {}, this.cache = e.cache || {
      clear: "manual"
    }, this.helpers = new k(), this.resolveCounter = 0, this.resolveNestedRelations = e.resolveNestedRelations || !0, this.stringifiedStoriesCache = {}, this.client = new ie({
      baseURL: r,
      timeout: e.timeout || 0,
      headers: s,
      responseInterceptor: e.responseInterceptor,
      fetch: e.fetch
    });
  }
  setComponentResolver(e) {
    this.richTextResolver.addNode("blok", t => {
      let r = "";
      return t.attrs.body && t.attrs.body.forEach(s => {
        r += e(s.component, s);
      }), {
        html: r
      };
    });
  }
  parseParams(e) {
    return e.token || (e.token = this.getToken()), e.cv || (e.cv = y[e.token]), Array.isArray(e.resolve_relations) && (e.resolve_relations = e.resolve_relations.join(",")), typeof e.resolve_relations < "u" && (e.resolve_level = 2), e;
  }
  factoryParamOptions(e, t) {
    return this.helpers.isCDNUrl(e) ? this.parseParams(t) : t;
  }
  makeRequest(e, t, r, s, i) {
    const n = this.factoryParamOptions(e, this.helpers.getOptionsPage(t, r, s));
    return this.cacheResponse(e, n, void 0, i);
  }
  get(e, t, r) {
    t || (t = {});
    const s = `/${e}`,
      i = this.factoryParamOptions(s, t);
    return this.cacheResponse(s, i, void 0, r);
  }
  async getAll(e, t, r, s) {
    const i = (t == null ? void 0 : t.per_page) || 25,
      n = `/${e}`,
      l = n.split("/"),
      a = r || l[l.length - 1],
      u = 1,
      h = await this.makeRequest(n, t, i, u, s),
      f = h.total ? Math.ceil(h.total / i) : 1,
      d = await this.helpers.asyncMap(this.helpers.range(u, f), p => this.makeRequest(n, t, i, p + 1, s));
    return this.helpers.flatMap([h, ...d], p => Object.values(p.data[a]));
  }
  post(e, t, r) {
    const s = `/${e}`;
    return Promise.resolve(this.throttle("post", s, t, r));
  }
  put(e, t, r) {
    const s = `/${e}`;
    return Promise.resolve(this.throttle("put", s, t, r));
  }
  delete(e, t, r) {
    const s = `/${e}`;
    return Promise.resolve(this.throttle("delete", s, t, r));
  }
  getStories(e, t) {
    return this._addResolveLevel(e), this.get("cdn/stories", e, t);
  }
  getStory(e, t, r) {
    return this._addResolveLevel(t), this.get(`cdn/stories/${e}`, t, r);
  }
  getToken() {
    return this.accessToken;
  }
  ejectInterceptor() {
    this.client.eject();
  }
  _addResolveLevel(e) {
    typeof e.resolve_relations < "u" && (e.resolve_level = 2);
  }
  _cleanCopy(e) {
    return JSON.parse(JSON.stringify(e));
  }
  _insertLinks(e, t, r) {
    const s = e[t];
    s && s.fieldtype == "multilink" && s.linktype == "story" && typeof s.id == "string" && this.links[r][s.id] ? s.story = this._cleanCopy(this.links[r][s.id]) : s && s.linktype === "story" && typeof s.uuid == "string" && this.links[r][s.uuid] && (s.story = this._cleanCopy(this.links[r][s.uuid]));
  }
  /**
   *
   * @param resolveId A counter number as a string
   * @param uuid The uuid of the story
   * @returns string | object
   */
  getStoryReference(e, t) {
    return this.relations[e][t] ? (this.stringifiedStoriesCache[t] || (this.stringifiedStoriesCache[t] = JSON.stringify(this.relations[e][t])), JSON.parse(this.stringifiedStoriesCache[t])) : t;
  }
  _insertRelations(e, t, r, s) {
    r.indexOf(`${e.component}.${t}`) > -1 && (typeof e[t] == "string" ? e[t] = this.getStoryReference(s, e[t]) : Array.isArray(e[t]) && (e[t] = e[t].map(i => this.getStoryReference(s, i)).filter(Boolean)));
  }
  iterateTree(e, t, r) {
    const s = i => {
      if (i != null) {
        if (i.constructor === Array) for (let n = 0; n < i.length; n++) s(i[n]);else if (i.constructor === Object) {
          if (i._stopResolving) return;
          for (const n in i) (i.component && i._uid || i.type === "link") && (this._insertRelations(i, n, t, r), this._insertLinks(i, n, r)), s(i[n]);
        }
      }
    };
    s(e.content);
  }
  async resolveLinks(e, t, r) {
    let s = [];
    if (e.link_uuids) {
      const i = e.link_uuids.length,
        n = [],
        l = 50;
      for (let a = 0; a < i; a += l) {
        const u = Math.min(i, a + l);
        n.push(e.link_uuids.slice(a, u));
      }
      for (let a = 0; a < n.length; a++) (await this.getStories({
        per_page: l,
        language: t.language,
        version: t.version,
        by_uuids: n[a].join(",")
      })).data.stories.forEach(h => {
        s.push(h);
      });
    } else s = e.links;
    s.forEach(i => {
      this.links[r][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  async resolveRelations(e, t, r) {
    let s = [];
    if (e.rel_uuids) {
      const i = e.rel_uuids.length,
        n = [],
        l = 50;
      for (let a = 0; a < i; a += l) {
        const u = Math.min(i, a + l);
        n.push(e.rel_uuids.slice(a, u));
      }
      for (let a = 0; a < n.length; a++) (await this.getStories({
        per_page: l,
        language: t.language,
        version: t.version,
        by_uuids: n[a].join(","),
        excluding_fields: t.excluding_fields
      })).data.stories.forEach(h => {
        s.push(h);
      });
    } else s = e.rels;
    s && s.length > 0 && s.forEach(i => {
      this.relations[r][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  /**
   *
   * @param responseData
   * @param params
   * @param resolveId
   * @description Resolves the relations and links of the stories
   * @returns Promise<void>
   *
   */
  async resolveStories(e, t, r) {
    var i, n;
    let s = [];
    if (this.links[r] = {}, this.relations[r] = {}, typeof t.resolve_relations < "u" && t.resolve_relations.length > 0 && (typeof t.resolve_relations == "string" && (s = t.resolve_relations.split(",")), await this.resolveRelations(e, t, r)), t.resolve_links && ["1", "story", "url", "link"].indexOf(t.resolve_links) > -1 && ((i = e.links) != null && i.length || (n = e.link_uuids) != null && n.length) && (await this.resolveLinks(e, t, r)), this.resolveNestedRelations) for (const l in this.relations[r]) this.iterateTree(this.relations[r][l], s, r);
    e.story ? this.iterateTree(e.story, s, r) : e.stories.forEach(l => {
      this.iterateTree(l, s, r);
    }), this.stringifiedStoriesCache = {}, delete this.links[r], delete this.relations[r];
  }
  async cacheResponse(e, t, r, s) {
    const i = this.helpers.stringify({
        url: e,
        params: t
      }),
      n = this.cacheProvider();
    if (this.cache.clear === "auto" && t.version === "draft" && (await this.flushCache()), t.version === "published" && e != "/cdn/spaces/me") {
      const l = await n.get(i);
      if (l) return Promise.resolve(l);
    }
    return new Promise(async (l, a) => {
      var u;
      try {
        const h = await this.throttle("get", e, t, s);
        if (h.status !== 200) return a(h);
        let f = {
          data: h.data,
          headers: h.headers
        };
        if ((u = h.headers) != null && u["per-page"] && (f = Object.assign({}, f, {
          perPage: h.headers["per-page"] ? parseInt(h.headers["per-page"]) : 0,
          total: h.headers["per-page"] ? parseInt(h.headers.total) : 0
        })), f.data.story || f.data.stories) {
          const d = this.resolveCounter = ++this.resolveCounter % 1e3;
          await this.resolveStories(f.data, t, `${d}`);
        }
        return t.version === "published" && e != "/cdn/spaces/me" && (await n.set(i, f)), f.data.cv && t.token && (t.version === "draft" && y[t.token] != f.data.cv && (await this.flushCache()), y[t.token] = t.cv ? t.cv : f.data.cv), l(f);
      } catch (h) {
        if (h.response && h.status === 429 && (r = typeof r > "u" ? 0 : r + 1, r < this.maxRetries)) return console.log(`Hit rate limit. Retrying in ${this.retriesDelay / 1e3} seconds.`), await this.helpers.delay(this.retriesDelay), this.cacheResponse(e, t, r).then(l).catch(a);
        a(h);
      }
    });
  }
  throttledRequest(e, t, r, s) {
    return this.client.setFetchOptions(s), this.client[e](t, r);
  }
  cacheVersions() {
    return y;
  }
  cacheVersion() {
    return y[this.accessToken];
  }
  setCacheVersion(e) {
    this.accessToken && (y[this.accessToken] = e);
  }
  clearCacheVersion() {
    this.accessToken && (y[this.accessToken] = 0);
  }
  cacheProvider() {
    switch (this.cache.type) {
      case "memory":
        return {
          get(e) {
            return Promise.resolve(m[e]);
          },
          getAll() {
            return Promise.resolve(m);
          },
          set(e, t) {
            return m[e] = t, Promise.resolve(void 0);
          },
          flush() {
            return m = {}, Promise.resolve(void 0);
          }
        };
      case "custom":
        if (this.cache.custom) return this.cache.custom;
      default:
        return {
          get() {
            return Promise.resolve();
          },
          getAll() {
            return Promise.resolve(void 0);
          },
          set() {
            return Promise.resolve(void 0);
          },
          flush() {
            return Promise.resolve(void 0);
          }
        };
    }
  }
  async flushCache() {
    return await this.cacheProvider().flush(), this.clearCacheVersion(), this;
  }
}
export { T as RichtextResolver, ee as RichtextSchema, $ as STORYBLOK_AGENT, R as STORYBLOK_JS_CLIENT_AGENT, ie as SbFetch, k as SbHelpers, oe as default };