<details
	[ngClass]="{
		'accordion-accent': variant === 'accent',
		'accordion-base': variant === 'base',
	}"
	[open]="isOpen"
	class="group accordion"
>
	<summary
		class="flex cursor-pointer items-start px-3 py-2 text-lg font-semibold md:px-4 md:py-3 md:text-xl [&::-webkit-details-marker]:hidden"
	>
		{{ title }}
		<div class="ml-auto mt-1 size-4 flex-shrink-0 text-black">
			<svg-icon
				class="hidden group-not-open:block"
				src="assets/icons/icon-plus.svg"
			/>
			<svg-icon
				class="hidden group-open:block"
				src="assets/icons/icon-minus.svg"
			/>
		</div>
	</summary>
	<main class="px-3 pb-2 text-base md:px-4 md:pb-3 md:text-lg">
		<ng-content />
	</main>
</details>
