/* istanbul ignore next */

import { HttpClient } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BehaviorSubject } from 'rxjs';

// TODO: VPL-23011 - This imports package.json in the entire build, this should be fixed but it's currently also done in environment.base.ts
import packageInfo from '@valk-nx/package.json';

export const languages = ['nl', 'ca', 'en', 'es', 'de', 'fr'] as const;
export const ASSETS_ROOT = new InjectionToken<string>('valk-core');

/* istanbul ignore next */
export function createTranslateLoader(
	http: HttpClient,
	assetsRoot: string,
): TranslateHttpLoader {
	return new TranslateHttpLoader(
		http,
		`${assetsRoot}/i18n/`,
		`.json?v=${packageInfo?.version || 1}`,
	);
}

export const fallbackLanguage = languages[0];
export type Language = (typeof languages)[number];
export type LanguageRecord = Record<Language, string>;

export const languagesLabels: LanguageRecord = {
	ca: 'Catalunya',
	de: 'Deutsch',
	en: 'English',
	es: 'Español',
	fr: 'Français',
	nl: 'Nederlands',
};
export const minimumSpendableLoyalAmount = 250;

export type LinkTarget = '_blank' | '_self' | '';

export const negativeHeader$: BehaviorSubject<boolean> =
	new BehaviorSubject<boolean>(true);

export const Loading = 'loading';
export const Success = 'success';
export const Failed = 'failed';
