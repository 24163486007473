import { CommonModule, isPlatformServer } from '@angular/common';
import {
	Component,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { map, Observable, switchMap } from 'rxjs';

import { AccordionComponent } from '@valk-nx/components/ui-accordion/src/lib/accordion';
import { CarouselComponent } from '@valk-nx/components/ui-carousel/src/lib/carousel';
import { CarouselItem } from '@valk-nx/components/ui-carousel/src/lib/carousel.interface';
import {
	ImageInterface,
	Sizes,
} from '@valk-nx/components/ui-image/src/lib/image.interface';
import { MiniCarouselComponent } from '@valk-nx/components/ui-mini-carousel/src/lib/mini-carousel';
import { PackageDetailsComponent } from '@valk-nx/components/ui-package-details/src/lib/package-details';
import { PricingComponent } from '@valk-nx/components/ui-pricing/src/lib/pricing';
import { ReadMoreComponent } from '@valk-nx/components/ui-read-more/src/lib/read-more';
import { TabMenuComponent } from '@valk-nx/components/ui-tab-menu/src/lib/tab-menu';
import { TabMenuItemDirective } from '@valk-nx/components/ui-tab-menu/src/lib/tab-menu.directive';
import { PersuadeCardComponent } from '@valk-nx/compositions/ui-persuade-card/src/lib/persuade-card.component';
import { negativeHeader$ } from '@valk-nx/core/lib/core';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { BOOKINGTOOL } from '@valk-nx/storyblok-services/src/lib/globals';
import { PackageData } from '@valk-nx/storyblok-services/src/lib/services/content.interface';
import {
	ContentService,
	HOTEL_SLUG,
} from '@valk-nx/storyblok-services/src/lib/services/content.service';
import { MetadataService } from '@valk-nx/storyblok-services/src/lib/services/metadata.service';
import { StoryblokService } from '@valk-nx/storyblok-services/src/lib/services/storyblok.service';

@Component({
	selector: 'sb-package-detail',
	templateUrl: './package-detail.component.html',
	standalone: true,
	imports: [
		AccordionComponent,
		CarouselComponent,
		CommonModule,
		MiniCarouselComponent,
		MarkdownModule,
		PersuadeCardComponent,
		PackageDetailsComponent,
		PricingComponent,
		ReadMoreComponent,
		StoryblokDirective,
		TabMenuComponent,
		TabMenuItemDirective,
		TranslateModule,
	],
})
export class PackageDetailComponent
	extends StoryblokRootDirective
	implements OnInit
{
	@Input({ required: true }) canonical!: string;

	package$: Observable<PackageData>;

	sizes = Sizes;
	images: CarouselItem[] = [];
	hotelImages: ImageInterface[] = [];
	activePackageIndex = 0;

	constructor(
		private readonly contentService: ContentService,
		private readonly sbService: StoryblokService,
		private metadataService: MetadataService,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		@Inject(HOTEL_SLUG) readonly hotelSlug: string,
		@Inject(BOOKINGTOOL) readonly bookingtool: string,
	) {
		super();
			this.package$ = this.sbService.translatedSlugs$.pipe(
			takeUntilDestroyed(),
			map((translatedSlugs) => {
				return (
					translatedSlugs.find((x) => x.selected) ||
					translatedSlugs[0]
				);
			}),
			switchMap((slug) =>
				this.contentService.getPackage(
					slug.path.split('/').pop(),
					slug.lang.toLowerCase(),
				),
			),
			map((response) => {
				const packageData = response.body.data;
				this.updateMeta(packageData);
				this.activePackageIndex = 0;

				this.images = packageData.images.map(
					(image: { src: string; alt: string }) => {
						return {
							image: {
								src: image.src,
								altText: image.alt,
							},
							heading: null,
							subHeading: null,
							link: null,
						};
					},
				);
				this.hotelImages = packageData.hotel.images.map(
					(image: { src: string; alt: string }) => {
						return {
							src: image.src,
							altText: image.alt,
							width: 0,
							height: 0,
						};
					},
				);

				if (this.images.length > 0) {
					negativeHeader$.next(true);
				}

				return packageData;
			}),
		);
	}

	ngOnInit() {
		negativeHeader$.next(false);
	}

	setActivePackageIndex(packageIndex: number): void {
		this.activePackageIndex = packageIndex;
	}

	updateMeta(packageData: PackageData): void {
		if (isPlatformServer(this.platformId)) {
			this.metadataService.updateMetadata({
				hideFromSiteMap: false,
				metaDescription: packageData.meta.description,
				metaTitle: packageData.meta.title,
				openGraphDescription: packageData.meta.description,
				openGraphTitle: packageData.meta.title,
				openGraphImage: {
					file: packageData.images[0]?.src,
					alt: packageData.images[0].alt,
				},
				openGraphType: 'website',
				robotsNoFollow: false,
				robotsNoIndex: false,
			});
		}
	}
}
