import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

import { ISbStoryData } from '@storyblok/js';
import { DynamicModule } from 'ng-dynamic-component';

import { ImageGridComponent } from '@valk-nx/compositions/ui-image-grid/src/lib/image-grid.component';
import { PhotoInterface } from '@valk-nx/core/lib/interfaces/image.interface';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Components } from '@valk-nx/storyblok-core/src/lib/components';
import { StoryblokDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok.directive';
import { StoryblokRootDirective } from '@valk-nx/storyblok-directives/src/lib/directives/storyblok-root.directive';
import { StoryblokHelper } from '@valk-nx/storyblok-helpers/src/lib/general/storyblok.helper';
import { CardImages } from '@valk-nx/storyblok-types/src/lib/types/storyblok.types';

@Component({
	selector: 'sb-card',
	templateUrl: './card.html',
	standalone: true,
	imports: [
		CommonModule,
		DynamicModule,
		StoryblokDirective,
		ImageGridComponent,
	],
})
export class CardComponent extends StoryblokRootDirective {
	@HostBinding('class') class = 'h-full';
	components: typeof Components;

	@Input() set images(images: CardImages[] | undefined) {
		this.flatImages = images
			?.filter(
				({ bynder }) =>
					bynder?.length > 0 &&
					bynder[0].files?.transformBaseUrl?.url,
			)
			.map(({ bynder, imageAlt }) => {
				return StoryblokHelper.mapBynderToPhotoInterface(
					bynder[0],
					imageAlt,
				);
			});
	}
	@Input({ required: true }) content!: ISbStoryData[];
	@Input() footer: ISbStoryData[] = [];
	@Input() isHorizontal = false;

	flatImages: PhotoInterface[] | undefined;

	constructor() {
		super();
		// eslint-disable-next-line @nx/enforce-module-boundaries
		import('@valk-nx/storyblok-core/src/lib/components')
			.then((cp) => {
				this.components = cp.Components;
			})
			.catch(
				/* istanbul ignore next */ (error) => {
					console.error(error);
				},
			);
	}
}
