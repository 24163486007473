@if (useButtonVariant()) {
	<div class="flex flex-col gap-2">
		<div [class]="numberOfColumbsTab()" class="grid grid-cols-2 gap-1">
			@for (tab of tabMenuItems; track $index) {
				<button
					[attr.data-test-id]="tab.name"
					[ngClass]="{
						'overflow-hidden !bg-accent-10 text-accent-100 before:absolute before:bottom-0 before:h-0.5 before:w-full before:bg-accent-100 before:content-empty':
							currentActiveTab === $index,
					}"
					(click)="setActiveTab($index)"
					class="relative inline-flex w-full items-center justify-center rounded-tab-menu border border-ui-100 bg-white p-3 font-semibold hover:border-ui-300"
				>
					{{ tab.label }}
				</button>
			}
		</div>
		<div class="card card-lg list-checkmarks flex flex-col gap-4">
			@if (tabMenuItems?.get(currentActiveTab)?.content) {
				<ng-container
					*ngTemplateOutlet="
						tabMenuItems.get(currentActiveTab).content
					"
				/>
			}
		</div>
	</div>
} @else {
	<div class="flex flex-col gap-4 md:gap-6 lg:gap-8" data-test-id="tab-menu">
		<div
			class="mb-px flex flex-row justify-between border-b-2 border-ui-200 sm:justify-start"
		>
			@for (tab of tabMenuItems; track $index) {
				<a
					[attr.data-test-class]="
						currentActiveTab === $index ? 'active' : 'inactive'
					"
					[attr.data-test-id]="tab.name"
					[ngClass]="{
						'border-accent-100 opacity-100':
							currentActiveTab === $index,
						'opacity-75': currentActiveTab !== $index,
					}"
					(click)="setActiveTab($index)"
					class="btn btn-text -mb-0.5 w-full rounded-none border-b-2 px-4 text-base outline-0 hover:border-accent-100 hover:opacity-100 sm:text-lg md:w-auto md:px-5"
				>
					{{ tab.label }}
				</a>
			}
		</div>
		@if (tabMenuItems?.get(currentActiveTab)?.content) {
			<ng-container
				*ngTemplateOutlet="tabMenuItems.get(currentActiveTab).content"
			/>
		}
	</div>
}
