import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	computed,
	ContentChildren,
	CUSTOM_ELEMENTS_SCHEMA,
	input,
	OnChanges,
	output,
	QueryList,
	SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { take } from 'rxjs';

import { GeneralHelper } from '@valk-nx/helpers/lib/general/general.helper';

import { TabMenuItemDirective } from './tab-menu.directive';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: `vp-tab-menu`,
	templateUrl: './tab-menu.html',
	standalone: true,
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		TabMenuItemDirective,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TabMenuComponent implements AfterViewInit, OnChanges {
	useButtonVariant = input(false);
	useScrollOnChange = input(false);
	useRouter = input(false);
	activeTab = input<number>(0);
	// Is only being used if useButtonVariant is true
	numberOfColumns = input<2 | 3 | 4>(3);

	@ContentChildren(TabMenuItemDirective)
	tabMenuItems: QueryList<TabMenuItemDirective> | undefined;

	// Is only being used if useButtonVariant is true
	numberOfColumbsTab = computed<string>(() => {
		switch (this.numberOfColumns()) {
			case 2:
				return 'md:grid-cols-2';
			case 3:
				return 'md:grid-cols-3';
			case 4:
				return 'md:grid-cols-4';
		}
	});

	selectedActiveTab = output<number>();

	tabNames: string[] = [];
	currentActiveTab = 0;

	constructor(
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly cd: ChangeDetectorRef,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['activeTab']) {
			this.currentActiveTab = changes['activeTab'].currentValue;
		}
	}

	ngAfterViewInit() {
		this.tabNames = this.tabMenuItems?.map((item) => item.name) || [];

		this.activatedRoute.fragment.pipe(take(1)).subscribe((fragment) => {
			if (!fragment) {
				return;
			}

			const activeTabIndex = this.tabNames?.indexOf(fragment);

			if (activeTabIndex !== undefined && activeTabIndex !== -1) {
				this.currentActiveTab = activeTabIndex;

				// Simulate a tick to make sure the selectedActiveTab event is emitted after the listening component is "done"
				setTimeout(() => {
					this.selectedActiveTab.emit(this.currentActiveTab);
				}, 0);
			} else {
				this.currentActiveTab = 0;
			}
		});
	}

	setActiveTab(tabIndex: number) {
		this.currentActiveTab = tabIndex;

		this.selectedActiveTab.emit(this.currentActiveTab);

		this.setHash(`#${this.tabNames[this.currentActiveTab]}`);
	}

	setHash(hash: string): void {
		const url = this.router?.url.split('#')[0];

		if (this.useRouter()) {
			this.router.navigateByUrl(url + hash);
		} else {
			window.history.pushState(null, '', url + hash);
		}

		if (this.useScrollOnChange()) {
			setTimeout(() => {
				GeneralHelper.scrollToElementWithId(
					hash.substring(1),
					200,
					false,
				);
			});
		}
	}
}
