let _ = !1;
const S = [],
  E = n => new Promise((e, t) => {
    if (typeof window > "u" || (window.storyblokRegisterEvent = r => {
      if (window.location === window.parent.location) {
        console.warn("You are not in Draft Mode or in the Visual Editor.");
        return;
      }
      _ ? r() : S.push(r);
    }, document.getElementById("storyblok-javascript-bridge"))) return;
    const s = document.createElement("script");
    s.async = !0, s.src = n, s.id = "storyblok-javascript-bridge", s.onerror = r => t(r), s.onload = r => {
      S.forEach(i => i()), _ = !0, e(r);
    }, document.getElementsByTagName("head")[0].appendChild(s);
  });
var I = Object.defineProperty,
  P = (n, e, t) => e in n ? I(n, e, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: t
  }) : n[e] = t,
  h = (n, e, t) => P(n, typeof e != "symbol" ? e + "" : e, t);
function j(n) {
  return !(n !== n || n === 1 / 0 || n === -1 / 0);
}
function C(n, e, t) {
  if (!j(e)) throw new TypeError("Expected `limit` to be a finite number");
  if (!j(t)) throw new TypeError("Expected `interval` to be a finite number");
  const s = [];
  let r = [],
    i = 0;
  const o = function () {
      i++;
      const a = setTimeout(function () {
        i--, s.length > 0 && o(), r = r.filter(function (u) {
          return u !== a;
        });
      }, t);
      r.indexOf(a) < 0 && r.push(a);
      const c = s.shift();
      c.resolve(n.apply(c.self, c.args));
    },
    l = function (...a) {
      const c = this;
      return new Promise(function (u, d) {
        s.push({
          resolve: u,
          reject: d,
          args: a,
          self: c
        }), i < e && o();
      });
    };
  return l.abort = function () {
    r.forEach(clearTimeout), r = [], s.forEach(function (a) {
      a.reject(function () {
        Error.call(this, "Throttled function aborted"), this.name = "AbortError";
      });
    }), s.length = 0;
  }, l;
}
class b {
  constructor() {
    h(this, "isCDNUrl", (e = "") => e.indexOf("/cdn/") > -1), h(this, "getOptionsPage", (e, t = 25, s = 1) => ({
      ...e,
      per_page: t,
      page: s
    })), h(this, "delay", e => new Promise(t => setTimeout(t, e))), h(this, "arrayFrom", (e = 0, t) => [...Array(e)].map(t)), h(this, "range", (e = 0, t = e) => {
      const s = Math.abs(t - e) || 0,
        r = e < t ? 1 : -1;
      return this.arrayFrom(s, (i, o) => o * r + e);
    }), h(this, "asyncMap", async (e, t) => Promise.all(e.map(t))), h(this, "flatMap", (e = [], t) => e.map(t).reduce((s, r) => [...s, ...r], [])), h(this, "escapeHTML", function (e) {
      const t = {
          "&": "&amp;",
          "<": "&lt;",
          ">": "&gt;",
          '"': "&quot;",
          "'": "&#39;"
        },
        s = /[&<>"']/g,
        r = RegExp(s.source);
      return e && r.test(e) ? e.replace(s, i => t[i]) : e;
    });
  }
  /**
   * @method stringify
   * @param  {Object} params
   * @param  {String} prefix
   * @param  {Boolean} isArray
   * @return {String} Stringified object
   */
  stringify(e, t, s) {
    const r = [];
    for (const i in e) {
      if (!Object.prototype.hasOwnProperty.call(e, i)) continue;
      const o = e[i],
        l = s ? "" : encodeURIComponent(i);
      let a;
      typeof o == "object" ? a = this.stringify(o, t ? t + encodeURIComponent("[" + l + "]") : l, Array.isArray(o)) : a = (t ? t + encodeURIComponent("[" + l + "]") : l) + "=" + encodeURIComponent(o), r.push(a);
    }
    return r.join("&");
  }
  /**
   * @method getRegionURL
   * @param  {String} regionCode region code, could be eu, us, cn, ap or ca
   * @return {String} The base URL of the region
   */
  getRegionURL(e) {
    const t = "api.storyblok.com",
      s = "api-us.storyblok.com",
      r = "app.storyblokchina.cn",
      i = "api-ap.storyblok.com",
      o = "api-ca.storyblok.com";
    switch (e) {
      case "us":
        return s;
      case "cn":
        return r;
      case "ap":
        return i;
      case "ca":
        return o;
      default:
        return t;
    }
  }
}
const A = function (n, e) {
    const t = {};
    for (const s in n) {
      const r = n[s];
      e.indexOf(s) > -1 && r !== null && (t[s] = r);
    }
    return t;
  },
  N = n => n === "email",
  L = () => ({
    singleTag: "hr"
  }),
  M = () => ({
    tag: "blockquote"
  }),
  z = () => ({
    tag: "ul"
  }),
  U = n => ({
    tag: ["pre", {
      tag: "code",
      attrs: n.attrs
    }]
  }),
  H = () => ({
    singleTag: "br"
  }),
  q = n => ({
    tag: `h${n.attrs.level}`
  }),
  B = n => ({
    singleTag: [{
      tag: "img",
      attrs: A(n.attrs, ["src", "alt", "title"])
    }]
  }),
  V = () => ({
    tag: "li"
  }),
  D = () => ({
    tag: "ol"
  }),
  J = () => ({
    tag: "p"
  }),
  F = n => ({
    tag: [{
      tag: "span",
      attrs: {
        "data-type": "emoji",
        "data-name": n.attrs.name,
        emoji: n.attrs.emoji
      }
    }]
  }),
  Y = () => ({
    tag: "b"
  }),
  K = () => ({
    tag: "s"
  }),
  W = () => ({
    tag: "u"
  }),
  G = () => ({
    tag: "strong"
  }),
  Q = () => ({
    tag: "code"
  }),
  X = () => ({
    tag: "i"
  }),
  Z = n => {
    if (!n.attrs) return {
      tag: ""
    };
    const e = new b().escapeHTML,
      t = {
        ...n.attrs
      },
      {
        linktype: s = "url"
      } = n.attrs;
    if (delete t.linktype, t.href && (t.href = e(n.attrs.href || "")), N(s) && (t.href = `mailto:${t.href}`), t.anchor && (t.href = `${t.href}#${t.anchor}`, delete t.anchor), t.custom) {
      for (const r in t.custom) t[r] = t.custom[r];
      delete t.custom;
    }
    return {
      tag: [{
        tag: "a",
        attrs: t
      }]
    };
  },
  ee = n => ({
    tag: [{
      tag: "span",
      attrs: n.attrs
    }]
  }),
  te = () => ({
    tag: "sub"
  }),
  se = () => ({
    tag: "sup"
  }),
  re = n => ({
    tag: [{
      tag: "span",
      attrs: n.attrs
    }]
  }),
  ie = n => {
    var e;
    return (e = n.attrs) != null && e.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `background-color:${n.attrs.color};`
        }
      }]
    } : {
      tag: ""
    };
  },
  ne = n => {
    var e;
    return (e = n.attrs) != null && e.color ? {
      tag: [{
        tag: "span",
        attrs: {
          style: `color:${n.attrs.color}`
        }
      }]
    } : {
      tag: ""
    };
  },
  oe = {
    nodes: {
      horizontal_rule: L,
      blockquote: M,
      bullet_list: z,
      code_block: U,
      hard_break: H,
      heading: q,
      image: B,
      list_item: V,
      ordered_list: D,
      paragraph: J,
      emoji: F
    },
    marks: {
      bold: Y,
      strike: K,
      underline: W,
      strong: G,
      code: Q,
      italic: X,
      link: Z,
      styled: ee,
      subscript: te,
      superscript: se,
      anchor: re,
      highlight: ie,
      textStyle: ne
    }
  },
  ae = function (n) {
    const e = {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        '"': "&quot;",
        "'": "&#39;"
      },
      t = /[&<>"']/g,
      s = RegExp(t.source);
    return n && s.test(n) ? n.replace(t, r => e[r]) : n;
  };
class le {
  constructor(e) {
    h(this, "marks"), h(this, "nodes"), e || (e = oe), this.marks = e.marks || [], this.nodes = e.nodes || [];
  }
  addNode(e, t) {
    this.nodes[e] = t;
  }
  addMark(e, t) {
    this.marks[e] = t;
  }
  render(e, t = {
    optimizeImages: !1
  }) {
    if (e && e.content && Array.isArray(e.content)) {
      let s = "";
      return e.content.forEach(r => {
        s += this.renderNode(r);
      }), t.optimizeImages ? this.optimizeImages(s, t.optimizeImages) : s;
    }
    return console.warn(`The render method must receive an Object with a "content" field.
			The "content" field must be an array of nodes as the type ISbRichtext.
			ISbRichtext:
				content?: ISbRichtext[]
				marks?: ISbRichtext[]
				attrs?: any
				text?: string
				type: string
				
				Example:
				{
					content: [
						{
							content: [
								{
									text: 'Hello World',
									type: 'text'
								}
							],
							type: 'paragraph'
						}
					],
					type: 'doc'
				}`), "";
  }
  optimizeImages(e, t) {
    let s = 0,
      r = 0,
      i = "",
      o = "";
    typeof t != "boolean" && (typeof t.width == "number" && t.width > 0 && (i += `width="${t.width}" `, s = t.width), typeof t.height == "number" && t.height > 0 && (i += `height="${t.height}" `, r = t.height), (t.loading === "lazy" || t.loading === "eager") && (i += `loading="${t.loading}" `), typeof t.class == "string" && t.class.length > 0 && (i += `class="${t.class}" `), t.filters && (typeof t.filters.blur == "number" && t.filters.blur >= 0 && t.filters.blur <= 100 && (o += `:blur(${t.filters.blur})`), typeof t.filters.brightness == "number" && t.filters.brightness >= -100 && t.filters.brightness <= 100 && (o += `:brightness(${t.filters.brightness})`), t.filters.fill && (t.filters.fill.match(/[0-9A-Fa-f]{6}/g) || t.filters.fill === "transparent") && (o += `:fill(${t.filters.fill})`), t.filters.format && ["webp", "png", "jpeg"].includes(t.filters.format) && (o += `:format(${t.filters.format})`), typeof t.filters.grayscale == "boolean" && t.filters.grayscale && (o += ":grayscale()"), typeof t.filters.quality == "number" && t.filters.quality >= 0 && t.filters.quality <= 100 && (o += `:quality(${t.filters.quality})`), t.filters.rotate && [90, 180, 270].includes(t.filters.rotate) && (o += `:rotate(${t.filters.rotate})`), o.length > 0 && (o = "/filters" + o))), i.length > 0 && (e = e.replace(/<img/g, `<img ${i.trim()}`));
    const l = s > 0 || r > 0 || o.length > 0 ? `${s}x${r}${o}` : "";
    return e = e.replace(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g, `a.storyblok.com/f/$1/$2.$3/m/${l}`), typeof t != "boolean" && (t.sizes || t.srcset) && (e = e.replace(/<img.*?src=["|'](.*?)["|']/g, a => {
      var c, u;
      const d = a.match(/a.storyblok.com\/f\/(\d+)\/([^.]+)\.(gif|jpg|jpeg|png|tif|tiff|bmp)/g);
      if (d && d.length > 0) {
        const p = {
          srcset: (c = t.srcset) == null ? void 0 : c.map(g => {
            if (typeof g == "number") return `//${d}/m/${g}x0${o} ${g}w`;
            if (typeof g == "object" && g.length === 2) {
              let k = 0,
                T = 0;
              return typeof g[0] == "number" && (k = g[0]), typeof g[1] == "number" && (T = g[1]), `//${d}/m/${k}x${T}${o} ${k}w`;
            }
          }).join(", "),
          sizes: (u = t.sizes) == null ? void 0 : u.map(g => g).join(", ")
        };
        let f = "";
        return p.srcset && (f += `srcset="${p.srcset}" `), p.sizes && (f += `sizes="${p.sizes}" `), a.replace(/<img/g, `<img ${f.trim()}`);
      }
      return a;
    })), e;
  }
  renderNode(e) {
    const t = [];
    e.marks && e.marks.forEach(r => {
      const i = this.getMatchingMark(r);
      i && i.tag !== "" && t.push(this.renderOpeningTag(i.tag));
    });
    const s = this.getMatchingNode(e);
    return s && s.tag && t.push(this.renderOpeningTag(s.tag)), e.content ? e.content.forEach(r => {
      t.push(this.renderNode(r));
    }) : e.text ? t.push(ae(e.text)) : s && s.singleTag ? t.push(this.renderTag(s.singleTag, " /")) : s && s.html ? t.push(s.html) : e.type === "emoji" && t.push(this.renderEmoji(e)), s && s.tag && t.push(this.renderClosingTag(s.tag)), e.marks && e.marks.slice(0).reverse().forEach(r => {
      const i = this.getMatchingMark(r);
      i && i.tag !== "" && t.push(this.renderClosingTag(i.tag));
    }), t.join("");
  }
  renderTag(e, t) {
    return e.constructor === String ? `<${e}${t}>` : e.map(s => {
      if (s.constructor === String) return `<${s}${t}>`;
      {
        let r = `<${s.tag}`;
        if (s.attrs) {
          for (const i in s.attrs) if (Object.prototype.hasOwnProperty.call(s.attrs, i)) {
            const o = s.attrs[i];
            o !== null && (r += ` ${i}="${o}"`);
          }
        }
        return `${r}${t}>`;
      }
    }).join("");
  }
  renderOpeningTag(e) {
    return this.renderTag(e, "");
  }
  renderClosingTag(e) {
    return e.constructor === String ? `</${e}>` : e.slice(0).reverse().map(t => t.constructor === String ? `</${t}>` : `</${t.tag}>`).join("");
  }
  getMatchingNode(e) {
    const t = this.nodes[e.type];
    if (typeof t == "function") return t(e);
  }
  getMatchingMark(e) {
    const t = this.marks[e.type];
    if (typeof t == "function") return t(e);
  }
  renderEmoji(e) {
    if (e.attrs.emoji) return e.attrs.emoji;
    const t = [{
      tag: "img",
      attrs: {
        src: e.attrs.fallbackImage,
        draggable: "false",
        loading: "lazy",
        align: "absmiddle"
      }
    }];
    return this.renderTag(t, " /");
  }
}
const v = le;
class ce {
  constructor(e) {
    h(this, "baseURL"), h(this, "timeout"), h(this, "headers"), h(this, "responseInterceptor"), h(this, "fetch"), h(this, "ejectInterceptor"), h(this, "url"), h(this, "parameters"), h(this, "fetchOptions"), this.baseURL = e.baseURL, this.headers = e.headers || new Headers(), this.timeout = e != null && e.timeout ? e.timeout * 1e3 : 0, this.responseInterceptor = e.responseInterceptor, this.fetch = (...t) => e.fetch ? e.fetch(...t) : fetch(...t), this.ejectInterceptor = !1, this.url = "", this.parameters = {}, this.fetchOptions = {};
  }
  /**
   *
   * @param url string
   * @param params ISbStoriesParams
   * @returns Promise<ISbResponse | Error>
   */
  get(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("get");
  }
  post(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("post");
  }
  put(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("put");
  }
  delete(e, t) {
    return this.url = e, this.parameters = t, this._methodHandler("delete");
  }
  async _responseHandler(e) {
    const t = [],
      s = {
        data: {},
        headers: {},
        status: 0,
        statusText: ""
      };
    e.status !== 204 && (await e.json().then(r => {
      s.data = r;
    }));
    for (const r of e.headers.entries()) t[r[0]] = r[1];
    return s.headers = {
      ...t
    }, s.status = e.status, s.statusText = e.statusText, s;
  }
  async _methodHandler(e) {
    let t = `${this.baseURL}${this.url}`,
      s = null;
    if (e === "get") {
      const a = new b();
      t = `${this.baseURL}${this.url}?${a.stringify(this.parameters)}`;
    } else s = JSON.stringify(this.parameters);
    const r = new URL(t),
      i = new AbortController(),
      {
        signal: o
      } = i;
    let l;
    this.timeout && (l = setTimeout(() => i.abort(), this.timeout));
    try {
      const a = await this.fetch(`${r}`, {
        method: e,
        headers: this.headers,
        body: s,
        signal: o,
        ...this.fetchOptions
      });
      this.timeout && clearTimeout(l);
      const c = await this._responseHandler(a);
      return this.responseInterceptor && !this.ejectInterceptor ? this._statusHandler(this.responseInterceptor(c)) : this._statusHandler(c);
    } catch (a) {
      return {
        message: a
      };
    }
  }
  setFetchOptions(e = {}) {
    Object.keys(e).length > 0 && "method" in e && delete e.method, this.fetchOptions = {
      ...e
    };
  }
  eject() {
    this.ejectInterceptor = !0;
  }
  _statusHandler(e) {
    const t = /20[0-6]/g;
    return new Promise((s, r) => {
      if (t.test(`${e.status}`)) return s(e);
      const i = {
        message: e.statusText,
        status: e.status,
        response: Array.isArray(e.data) ? e.data[0] : e.data.error || e.data.slug
      };
      r(i);
    });
  }
}
const he = ce,
  x = "SB-Agent",
  w = {
    defaultAgentName: "SB-JS-CLIENT",
    defaultAgentVersion: "SB-Agent-Version",
    packageVersion: "6.0.0"
  };
let y = {};
const m = {};
class ue {
  /**
   *
   * @param config ISbConfig interface
   * @param endpoint string, optional
   */
  constructor(e, t) {
    h(this, "client"), h(this, "maxRetries"), h(this, "retriesDelay"), h(this, "throttle"), h(this, "accessToken"), h(this, "cache"), h(this, "helpers"), h(this, "resolveCounter"), h(this, "relations"), h(this, "links"), h(this, "richTextResolver"), h(this, "resolveNestedRelations"), h(this, "stringifiedStoriesCache");
    let s = e.endpoint || t;
    if (!s) {
      const o = new b().getRegionURL,
        l = e.https === !1 ? "http" : "https";
      e.oauthToken ? s = `${l}://${o(e.region)}/v1` : s = `${l}://${o(e.region)}/v2`;
    }
    const r = new Headers();
    r.set("Content-Type", "application/json"), r.set("Accept", "application/json"), e.headers && e.headers.entries().toArray().forEach(([o, l]) => {
      r.set(o, l);
    }), r.has(x) || (r.set(x, w.defaultAgentName), r.set(w.defaultAgentVersion, w.packageVersion));
    let i = 5;
    e.oauthToken && (r.set("Authorization", e.oauthToken), i = 3), e.rateLimit && (i = e.rateLimit), e.richTextSchema ? this.richTextResolver = new v(e.richTextSchema) : this.richTextResolver = new v(), e.componentResolver && this.setComponentResolver(e.componentResolver), this.maxRetries = e.maxRetries || 10, this.retriesDelay = 300, this.throttle = C(this.throttledRequest, i, 1e3), this.accessToken = e.accessToken || "", this.relations = {}, this.links = {}, this.cache = e.cache || {
      clear: "manual"
    }, this.helpers = new b(), this.resolveCounter = 0, this.resolveNestedRelations = e.resolveNestedRelations || !0, this.stringifiedStoriesCache = {}, this.client = new he({
      baseURL: s,
      timeout: e.timeout || 0,
      headers: r,
      responseInterceptor: e.responseInterceptor,
      fetch: e.fetch
    });
  }
  setComponentResolver(e) {
    this.richTextResolver.addNode("blok", t => {
      let s = "";
      return t.attrs.body && t.attrs.body.forEach(r => {
        s += e(r.component, r);
      }), {
        html: s
      };
    });
  }
  parseParams(e) {
    return e.token || (e.token = this.getToken()), e.cv || (e.cv = m[e.token]), Array.isArray(e.resolve_relations) && (e.resolve_relations = e.resolve_relations.join(",")), typeof e.resolve_relations < "u" && (e.resolve_level = 2), e;
  }
  factoryParamOptions(e, t) {
    return this.helpers.isCDNUrl(e) ? this.parseParams(t) : t;
  }
  makeRequest(e, t, s, r, i) {
    const o = this.factoryParamOptions(e, this.helpers.getOptionsPage(t, s, r));
    return this.cacheResponse(e, o, void 0, i);
  }
  get(e, t, s) {
    t || (t = {});
    const r = `/${e}`,
      i = this.factoryParamOptions(r, t);
    return this.cacheResponse(r, i, void 0, s);
  }
  async getAll(e, t, s, r) {
    const i = (t == null ? void 0 : t.per_page) || 25,
      o = `/${e}`,
      l = o.split("/"),
      a = s || l[l.length - 1],
      c = 1,
      u = await this.makeRequest(o, t, i, c, r),
      d = u.total ? Math.ceil(u.total / i) : 1,
      p = await this.helpers.asyncMap(this.helpers.range(c, d), f => this.makeRequest(o, t, i, f + 1, r));
    return this.helpers.flatMap([u, ...p], f => Object.values(f.data[a]));
  }
  post(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("post", r, t, s));
  }
  put(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("put", r, t, s));
  }
  delete(e, t, s) {
    const r = `/${e}`;
    return Promise.resolve(this.throttle("delete", r, t, s));
  }
  getStories(e, t) {
    return this._addResolveLevel(e), this.get("cdn/stories", e, t);
  }
  getStory(e, t, s) {
    return this._addResolveLevel(t), this.get(`cdn/stories/${e}`, t, s);
  }
  getToken() {
    return this.accessToken;
  }
  ejectInterceptor() {
    this.client.eject();
  }
  _addResolveLevel(e) {
    typeof e.resolve_relations < "u" && (e.resolve_level = 2);
  }
  _cleanCopy(e) {
    return JSON.parse(JSON.stringify(e));
  }
  _insertLinks(e, t, s) {
    const r = e[t];
    r && r.fieldtype == "multilink" && r.linktype == "story" && typeof r.id == "string" && this.links[s][r.id] ? r.story = this._cleanCopy(this.links[s][r.id]) : r && r.linktype === "story" && typeof r.uuid == "string" && this.links[s][r.uuid] && (r.story = this._cleanCopy(this.links[s][r.uuid]));
  }
  /**
   *
   * @param resolveId A counter number as a string
   * @param uuid The uuid of the story
   * @returns string | object
   */
  getStoryReference(e, t) {
    return this.relations[e][t] ? (this.stringifiedStoriesCache[t] || (this.stringifiedStoriesCache[t] = JSON.stringify(this.relations[e][t])), JSON.parse(this.stringifiedStoriesCache[t])) : t;
  }
  _insertRelations(e, t, s, r) {
    s.indexOf(`${e.component}.${t}`) > -1 && (typeof e[t] == "string" ? e[t] = this.getStoryReference(r, e[t]) : Array.isArray(e[t]) && (e[t] = e[t].map(i => this.getStoryReference(r, i)).filter(Boolean)));
  }
  iterateTree(e, t, s) {
    const r = i => {
      if (i != null) {
        if (i.constructor === Array) for (let o = 0; o < i.length; o++) r(i[o]);else if (i.constructor === Object) {
          if (i._stopResolving) return;
          for (const o in i) (i.component && i._uid || i.type === "link") && (this._insertRelations(i, o, t, s), this._insertLinks(i, o, s)), r(i[o]);
        }
      }
    };
    r(e.content);
  }
  async resolveLinks(e, t, s) {
    let r = [];
    if (e.link_uuids) {
      const i = e.link_uuids.length,
        o = [],
        l = 50;
      for (let a = 0; a < i; a += l) {
        const c = Math.min(i, a + l);
        o.push(e.link_uuids.slice(a, c));
      }
      for (let a = 0; a < o.length; a++) (await this.getStories({
        per_page: l,
        language: t.language,
        version: t.version,
        by_uuids: o[a].join(",")
      })).data.stories.forEach(c => {
        r.push(c);
      });
    } else r = e.links;
    r.forEach(i => {
      this.links[s][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  async resolveRelations(e, t, s) {
    let r = [];
    if (e.rel_uuids) {
      const i = e.rel_uuids.length,
        o = [],
        l = 50;
      for (let a = 0; a < i; a += l) {
        const c = Math.min(i, a + l);
        o.push(e.rel_uuids.slice(a, c));
      }
      for (let a = 0; a < o.length; a++) (await this.getStories({
        per_page: l,
        language: t.language,
        version: t.version,
        by_uuids: o[a].join(","),
        excluding_fields: t.excluding_fields
      })).data.stories.forEach(c => {
        r.push(c);
      });
    } else r = e.rels;
    r && r.length > 0 && r.forEach(i => {
      this.relations[s][i.uuid] = {
        ...i,
        _stopResolving: !0
      };
    });
  }
  /**
   *
   * @param responseData
   * @param params
   * @param resolveId
   * @description Resolves the relations and links of the stories
   * @returns Promise<void>
   *
   */
  async resolveStories(e, t, s) {
    var r, i;
    let o = [];
    if (this.links[s] = {}, this.relations[s] = {}, typeof t.resolve_relations < "u" && t.resolve_relations.length > 0 && (typeof t.resolve_relations == "string" && (o = t.resolve_relations.split(",")), await this.resolveRelations(e, t, s)), t.resolve_links && ["1", "story", "url", "link"].indexOf(t.resolve_links) > -1 && ((r = e.links) != null && r.length || (i = e.link_uuids) != null && i.length) && (await this.resolveLinks(e, t, s)), this.resolveNestedRelations) for (const l in this.relations[s]) this.iterateTree(this.relations[s][l], o, s);
    e.story ? this.iterateTree(e.story, o, s) : e.stories.forEach(l => {
      this.iterateTree(l, o, s);
    }), this.stringifiedStoriesCache = {}, delete this.links[s], delete this.relations[s];
  }
  async cacheResponse(e, t, s, r) {
    const i = this.helpers.stringify({
        url: e,
        params: t
      }),
      o = this.cacheProvider();
    if (this.cache.clear === "auto" && t.version === "draft" && (await this.flushCache()), t.version === "published" && e != "/cdn/spaces/me") {
      const l = await o.get(i);
      if (l) return Promise.resolve(l);
    }
    return new Promise(async (l, a) => {
      var c;
      try {
        const u = await this.throttle("get", e, t, r);
        if (u.status !== 200) return a(u);
        let d = {
          data: u.data,
          headers: u.headers
        };
        if ((c = u.headers) != null && c["per-page"] && (d = Object.assign({}, d, {
          perPage: u.headers["per-page"] ? parseInt(u.headers["per-page"]) : 0,
          total: u.headers["per-page"] ? parseInt(u.headers.total) : 0
        })), d.data.story || d.data.stories) {
          const p = this.resolveCounter = ++this.resolveCounter % 1e3;
          await this.resolveStories(d.data, t, `${p}`);
        }
        return t.version === "published" && e != "/cdn/spaces/me" && (await o.set(i, d)), d.data.cv && t.token && (t.version === "draft" && m[t.token] != d.data.cv && (await this.flushCache()), m[t.token] = t.cv ? t.cv : d.data.cv), l(d);
      } catch (u) {
        if (u.response && u.status === 429 && (s = typeof s > "u" ? 0 : s + 1, s < this.maxRetries)) return console.log(`Hit rate limit. Retrying in ${this.retriesDelay / 1e3} seconds.`), await this.helpers.delay(this.retriesDelay), this.cacheResponse(e, t, s).then(l).catch(a);
        a(u);
      }
    });
  }
  throttledRequest(e, t, s, r) {
    return this.client.setFetchOptions(r), this.client[e](t, s);
  }
  cacheVersions() {
    return m;
  }
  cacheVersion() {
    return m[this.accessToken];
  }
  setCacheVersion(e) {
    this.accessToken && (m[this.accessToken] = e);
  }
  clearCacheVersion() {
    this.accessToken && (m[this.accessToken] = 0);
  }
  cacheProvider() {
    switch (this.cache.type) {
      case "memory":
        return {
          get(e) {
            return Promise.resolve(y[e]);
          },
          getAll() {
            return Promise.resolve(y);
          },
          set(e, t) {
            return y[e] = t, Promise.resolve(void 0);
          },
          flush() {
            return y = {}, Promise.resolve(void 0);
          }
        };
      case "custom":
        if (this.cache.custom) return this.cache.custom;
      default:
        return {
          get() {
            return Promise.resolve();
          },
          getAll() {
            return Promise.resolve(void 0);
          },
          set() {
            return Promise.resolve(void 0);
          },
          flush() {
            return Promise.resolve(void 0);
          }
        };
    }
  }
  async flushCache() {
    return await this.cacheProvider().flush(), this.clearCacheVersion(), this;
  }
}
const pe = (n = {}) => {
    const {
      apiOptions: e
    } = n;
    if (!e.accessToken) {
      console.error("You need to provide an access token to interact with Storyblok API. Read https://www.storyblok.com/docs/api/content-delivery#topics/authentication");
      return;
    }
    return {
      storyblokApi: new ue(e)
    };
  },
  ge = n => {
    if (typeof n != "object" || typeof n._editable > "u") return {};
    try {
      const e = JSON.parse(n._editable.replace(/^<!--#storyblok#/, "").replace(/-->$/, ""));
      return e ? {
        "data-blok-c": JSON.stringify(e),
        "data-blok-uid": e.id + "-" + e.uid
      } : {};
    } catch {
      return {};
    }
  };
let R,
  $ = "https://app.storyblok.com/f/storyblok-v2-latest.js";
const fe = (n, e, t = {}) => {
    var l;
    const r = !(typeof window > "u") && typeof window.storyblokRegisterEvent < "u",
      o = +new URL((l = window.location) == null ? void 0 : l.href).searchParams.get("_storyblok") === n;
    if (!(!r || !o)) {
      if (!n) {
        console.warn("Story ID is not defined. Please provide a valid ID.");
        return;
      }
      window.storyblokRegisterEvent(() => {
        new window.StoryblokBridge(t).on(["input", "published", "change"], c => {
          c.action === "input" && c.story.id === n ? e(c.story) : (c.action === "change" || c.action === "published") && c.storyId === n && window.location.reload();
        });
      });
    }
  },
  me = (n = {}) => {
    var d, p;
    const {
      bridge: e,
      accessToken: t,
      use: s = [],
      apiOptions: r = {},
      richText: i = {},
      bridgeUrl: o
    } = n;
    r.accessToken = r.accessToken || t;
    const l = {
      bridge: e,
      apiOptions: r
    };
    let a = {};
    s.forEach(f => {
      a = {
        ...a,
        ...f(l)
      };
    }), o && ($ = o);
    const u = !(typeof window > "u") && ((p = (d = window.location) == null ? void 0 : d.search) == null ? void 0 : p.includes("_storyblok_tk"));
    return e !== !1 && u && E($), R = new v(i.schema), i.resolver && O(R, i.resolver), a;
  },
  O = (n, e) => {
    n.addNode("blok", t => {
      let s = "";
      return t.attrs.body.forEach(r => {
        s += e(r.component, r);
      }), {
        html: s
      };
    });
  },
  de = n => !n || !(n != null && n.content.some(e => e.content || e.type === "blok" || e.type === "horizontal_rule")),
  ye = (n, e, t) => {
    let s = t || R;
    if (!s) {
      console.error("Please initialize the Storyblok SDK before calling the renderRichText function");
      return;
    }
    return de(n) ? "" : (e && (s = new v(e.schema), e.resolver && O(s, e.resolver)), s.render(n));
  },
  be = () => E($);
export { v as RichTextResolver, oe as RichTextSchema, pe as apiPlugin, de as isRichTextEmpty, be as loadStoryblokBridge, fe as registerStoryblokBridge, ye as renderRichText, ge as storyblokEditable, me as storyblokInit, fe as useStoryblokBridge };